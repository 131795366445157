import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import ContactForm from "../../components/contact-form"
import IconMap from "../../images/graphic-elements/icon-map-marker.svg";
import IconPhone from "../../images/graphic-elements/icon-phone.svg";

const ContactHome = (props) => {
  const { data } = props
  const { edges: posts } = data.allMarkdownRemark
  return (
    <>    
        

                <div className="content-container flex">

                  <div className="flex-column col-2">

                    <div className="form-wrapper">
                        <h2>Contact Enquiry</h2>
                        <ContactForm />
                    </div>

                  </div>


                  {posts &&
                    posts.map(({ node: post }) => (
                        <>

                          {(post.frontmatter.address1 || post.frontmatter.address2 || post.frontmatter.phone) &&

                            <div className="flex-column col-2">

                              <div className="details-wrapper">
                                <h2>{post.frontmatter.title}</h2>

                                {(post.frontmatter.address1 || post.frontmatter.address2) &&
                                  <div className="address">
                                      <img className="icon" src={IconMap} />
                                      <span className="address-line">{post.frontmatter.address1}</span>
                                      <span className="address-line">{post.frontmatter.address2}</span>
                                  </div>
                                }

                                {post.frontmatter.phone &&
                                  <div className="phone">
                                      <img className="icon" src={IconPhone} />
                                      <a className="phone-link" href={`tel:${post.frontmatter.phone}`}>{post.frontmatter.phone}</a>
                                  </div>
                                }
                              </div>
                              
                            </div>

                            }
                          </>
                    ))}                  

                    
                </div>

            

    </>
  )
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query ContactFormQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "contact-form" } } }
        ) {
        edges {
            node {
                id
                frontmatter {
                    title
                    templateKey
                    sectionCode
                    address1
                    address2
                    phone
                }
            }
        }
      }
      }
    `}
    render={(data) => <ContactHome data={data} />}
  />
)
