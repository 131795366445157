import React from 'react'
import { navigate } from 'gatsby-link'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (

      <form
        name="contact"
        method="POST"
        action="/contact-thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
      >

          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contact" />

          <div hidden>
            <label>
              Don’t fill this out:{' '}
              <input name="bot-field" onChange={this.handleChange} />
            </label>
          </div>

          <div className="field col-1">
            <label className="label" htmlFor={'orgname'}>
              Organisation Name
            </label>
            <div className="control">
              <input
                className="input"
                type={'text'}
                name={'orgname'}
                onChange={this.handleChange}
                id={'orgname'}
                placeholder=""
                required={false}
              />
            </div>
          </div>

          <div className="field col-2">
            <label className="label" htmlFor={'fname'}>
              First Name
            </label>
            <div className="control">
              <input
                className="input"
                type={'text'}
                name={'fname'}
                onChange={this.handleChange}
                id={'fname'}
                placeholder="e.g. Jane"
                required={true}
              />
            </div>
          </div>

          <div className="field col-2">
            <label className="label" htmlFor={'lname'}>
              Last Name
            </label>
            <div className="control">
              <input
                className="input"
                type={'text'}
                name={'lname'}
                onChange={this.handleChange}
                id={'lname'}
                placeholder="e.g. Smith"
                required={true}
              />
            </div>
          </div>

          <div className="field col-1">
            <label className="label" htmlFor={'email'}>
              Email
            </label>
            <div className="control">
              <input
                className="input"
                type={'email'}
                name={'email'}
                onChange={this.handleChange}
                id={'email'}
                placeholder="e.g. j.smith@email.com"
                required={true}
              />
            </div>
          </div>

          <div className="field col-1 enquiry-field">
            <label className="label" htmlFor={'message'}>
              Enquiry...
                </label>
            <div className="control">
              <textarea
                className="textarea"
                name={'message'}
                onChange={this.handleChange}
                id={'message'}
                // placeholder="Enquiry..."
                required={true}
              />
            </div>
          </div>

          <div className="field">
            <button className="btn btn-form-submit" type="submit">
              Submit
            </button>
          </div>

      </form>
    )
  }
}
