import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactUsContentTemplate from "../templates/contact-us/contact-us-content-template"
import {Helmet} from 'react-helmet'

import ContactSection from "../templates/contact-us/contact-us-details-template"


const ContactUsPage = () => (
  <>
    <Layout>
      <Helmet>
        <body className="inner-page no-hero" data-active-page="page-contact-us" />
      </Helmet>
      <SEO title="Contact Us" />
      <section className="content-container">
        <ContactUsContentTemplate />
      </section>
      <ContactSection />
    </Layout>
  </>
)

export default ContactUsPage
